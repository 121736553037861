import { imageLoader } from 'next-image-loader/build/image-loader'

function isRelativePath(text) {
  const RGX_LEADING_SLASH = /^\//
  return RGX_LEADING_SLASH.test(text)
}

imageLoader.loader = ({ src }) => {
  const RGX_TRAILING_SLASH = /\/$/

  if (
    process.env.NEXT_PUBLIC_CDN_ASSETS_ENABLED !== 'true' ||
    !isRelativePath(src)
  ) {
    return src
  }

  return `${
    process.env.NEXT_PUBLIC_STATIC_GCS_CDN?.replace(RGX_TRAILING_SLASH, '') ??
    ''
  }${src}`
}
